import * as React from "react"
import { styles } from "../styling/general"
import { TableRow, Theme } from "@mui/material"
import { SxProps } from "@mui/system"
import { useCallback } from "react"

interface IProps {
  item: any
  children: any
  selected?: boolean
  onSelected: (item: any) => void
  sx?: SxProps<Theme>
}

/**
 * This component will be used to handle the clicking and selecting of a table row.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const TableRowSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { item, children, sx, onSelected, selected = false } = props

  const handleClick = useCallback(
    (item: any) => (e: any) => {
      if (e.target.tagName === "TD") {
        onSelected(item)
      }
    },
    []
  )

  return (
    <TableRow
      hover
      sx={{
        ...styles.tableSelect,
        ...sx,
        backgroundColor: selected ? "#eee" : "transparent",
      }}
      onClick={handleClick(item)}
    >
      {children}
    </TableRow>
  )
}

export default TableRowSelect
