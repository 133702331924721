import ISettingsState from "./models/ISettingsState"
import IAction from "../../shared/models/IAction"
import {
  SETTINGS_SET_CURRENT_USER,
  SETTINGS_SET_FILTERS,
  SETTINGS_SET_ORDERING,
  SETTINGS_SET_PAGE,
  SETTINGS_SET_TITLE,
} from "./types"

const initialState: ISettingsState = {
  title: "",
  currentUser: null,
  paging: null,
}

/**
 * Reducer for managing the redux store settings state.
 *
 * @param {ISettingsState} state the current state.
 * @param {IAction} action to adjust the state.
 * @returns {ISettingsState} update state
 */
export default (state: ISettingsState = initialState, action: IAction): ISettingsState => {
  switch (action.type) {
    case SETTINGS_SET_TITLE: {
      return { ...state, title: action.payload }
    }
    case SETTINGS_SET_CURRENT_USER: {
      return { ...state, currentUser: action.payload }
    }
    case SETTINGS_SET_ORDERING:
    case SETTINGS_SET_FILTERS:
    case SETTINGS_SET_PAGE: {
      const paging = state.paging !== null ? state.paging : {}
      const path: string = action.payload.path
      const thePaging: any = {
        ...paging,
        [path]: {
          ...(path in paging ? (paging as any)[path] : {}),
          ...action.payload,
        },
      }
      return {
        ...state,
        paging: thePaging,
      }
    }
  }
  return state
}
