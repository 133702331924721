import * as React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import { IBooksStore } from "../../store"
import { Control } from "react-hook-form/dist/types/form"
import useAuth from "../hooks/useAuth"

interface IProps {
  control: Control
  defaultValue?: number | null
}

/**
 * Component for selecting a client.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ClientFormSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { defaultValue = null, control } = props
  const { currentUser } = useAuth()
  const { currentClient } = useSelector((state: IBooksStore) => ({
    currentClient: state.clients.item,
  }))

  return (
    <>
      {currentUser?.user.profile.clients != null && currentClient != null && (
        <FormControl fullWidth>
          <InputLabel required id="client-select-label">
            Client
          </InputLabel>
          <Controller
            name="client"
            render={({ field, fieldState }) => (
              <Select
                required
                labelId="client-select-label"
                id="client-select"
                label="Client"
                error={Boolean(fieldState.error)}
                // helperText={fieldState.error?.message}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              >
                {currentUser?.user.profile.clients?.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            defaultValue={defaultValue !== null ? `${defaultValue}` : `${currentClient.id}`}
            control={control}
            rules={{ required: true }}
          />
        </FormControl>
      )}
    </>
  )
}

export default ClientFormSelect
