import { ErrorOption, FieldValues, UseFormSetError } from "react-hook-form"

/**
 * Check if the object is a connection error.
 *
 * @param {any} obj to check.
 * @returns {boolean} true if it is a connection error.
 */
export const isConnectionError = (obj: any): obj is IConnectionError => obj?.objType === "IConnectionError"

export type TSetFormError = (name: string, error: ErrorOption, options?: { shouldFocus: boolean }) => void

/**
 * Set the form errors from the connection error.
 *
 * @param {IConnectionError} errors to set in the form.
 * @param {UseFormSetError} setError where to set the errors.
 */
export const setFormConnectionErrors = <T extends FieldValues>(
  errors: IConnectionError,
  setError: UseFormSetError<T>
): void => {
  Object.keys(errors).forEach(field => {
    const messages = errors.data[field]
    let message = ""
    if (Array.isArray(messages)) {
      message += messages.join(", ")
    } else {
      message += Object.values(messages).join(", ")
    }
    setError(field as any, { type: "manual", message })
  })
}

export const CONNECTION_ERROR = { data: { error: ["Connection Error"] }, objType: "Error", code: "0" }

interface MapType {
  [id: string]: string[]
}

export interface IConnectionError {
  objType: string
  data: MapType
  code: string
}
