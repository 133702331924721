import { IOrdering } from "../models/IOrdering"
import { Box, TableSortLabel } from "@mui/material"
import { visuallyHidden } from "@mui/utils"
import * as React from "react"
import { useCallback } from "react"

interface IProps {
  field: string
  title: string
  ordering?: IOrdering // Current sort state.
  onOrdering?: (ordering: IOrdering) => void
}

/**
 * This component will be used to sort a table column.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const TableOrdering: React.FunctionComponent<IProps> = (props: IProps) => {
  const { field, title, ordering, onOrdering } = props

  const createSortHandler = useCallback(
    (ordering?: IOrdering) => (_e: React.MouseEvent<unknown>) => {
      if (onOrdering != null && ordering != null) {
        onOrdering(ordering)
      }
    },
    []
  )

  return onOrdering !== null ? (
    <TableSortLabel
      active={ordering?.field === field}
      direction={ordering?.field === field ? ordering?.direction : "asc"}
      onClick={createSortHandler({ direction: ordering?.direction === "asc" ? "desc" : "asc", field })}
    >
      {title}
      {ordering?.field === field ? (
        <Box component="span" sx={{ ...visuallyHidden }}>
          {ordering.direction === "desc" ? "sorted descending" : "sorted ascending"}
        </Box>
      ) : null}
    </TableSortLabel>
  ) : (
    <Box>{title}</Box>
  )
}

export default TableOrdering
