import { navigate } from "gatsby"
import IToken from "../models/IToken"
import ICredentials from "../models/ICredentials"
import ICurrentUser from "../models/ICurrentUser"

const isBrowser = typeof window !== `undefined`

export const BASE_URL = process.env.GATSBY_BASE_URL !== undefined ? process.env.GATSBY_BASE_URL : ""

/**
 * Get url params with a token.
 *
 * @param {IToken} token to set in the header.
 * @returns {any} with the token.
 */
const getParams = (token: IToken): any => ({
  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${token.access}`,
  },
  referrerPolicy: "same-origin",
  method: "GET",
  mode: "cors",
})

/**
 * Get the current user or null
 *
 * @returns {ICurrentUser | null} to the current user if logged in.
 */
export const getCurrentUser = (): ICurrentUser | null =>
  window?.localStorage.currentUser !== undefined ? JSON.parse(window.localStorage.currentUser) : null

/**
 * Set the current user or clear them.
 *
 * @param {ICurrentUser | null} currentUser to set.
 */
const setCurrentUser = (currentUser: ICurrentUser | null): void => {
  window.localStorage.currentUser = JSON.stringify(currentUser)
}

/**
 * Load the current user using a token.
 *
 * @param {IToken} token to load the user with.
 * @returns {Promise<ICurrentUser | null>} a promise for the current user or null
 */
const loadUserWithToken = async (token: IToken): Promise<ICurrentUser | null> => {
  const users = await fetch(`${BASE_URL}/users/me/`, getParams(token)).then(async resp => await resp.json())
  const user = users[0]

  const clients = await fetch(`${BASE_URL}/users/clients/`, getParams(token)).then(async resp => await resp.json())

  setCurrentUser({ user, clients, token })
  return getCurrentUser()
}

/**
 * Handle a log with user credentials.
 *
 * @param {ICredentials} credentials with username and password.
 * @returns {Promise<ICurrentUser | null>} a promise for the current user or null
 */
export const handleLogin = async (credentials: ICredentials): Promise<ICurrentUser | null> => {
  const token = await fetch(`${BASE_URL}/token/`, {
    headers: { "content-type": "application/json" },
    referrerPolicy: "same-origin",
    body: JSON.stringify(credentials),
    method: "POST",
    mode: "cors",
  }).then(async resp => await resp.json())
  return await loadUserWithToken(token)
}

/**
 * Handle a log with user credentials.
 *
 * @param {string} accessToken from google or where ever.
 * @returns {Promise<ICurrentUser | null>} a promise for the current user or null
 */
export const handleLoginWithToken = async (accessToken: string): Promise<ICurrentUser | null> => {
  const resp = await fetch(`${BASE_URL}/rest-auth/google/`, {
    headers: {
      "content-type": "application/json",
    },
    referrerPolicy: "same-origin",
    method: "POST",
    body: JSON.stringify({ access_token: accessToken }),
    mode: "cors",
  })
  if (resp.ok) {
    const token = await resp.json()
    const tokenReMapped = {
      refresh: token.refresh_token,
      access: token.access_token,
    }
    return await loadUserWithToken(tokenReMapped)
  }
  return null
}

/**
 * Check to see if the user is logged in.
 *
 * @returns {boolean} true if the user is logged in.
 */
export const isLoggedIn = (): boolean => {
  if (!isBrowser) {
    return false
  }

  const user = getCurrentUser()
  return user !== null
}

/**
 * Logout the user.
 */
export const logout = (): void => {
  if (!isBrowser) {
    return
  }
  void (async () => {
    setCurrentUser(null)
    await navigate(`/`)
  })()
}
