import IClient from "./IClient"
import IStand from "./IStand"

export const PRODUCT_ENDPOINT = `users/products`

export default interface IProduct {
  id: number
  name: string
  min_length: number
  cut_to_length: number
  min_butt: number
  max_butt: number
  min_dbh: number
  max_dbh: number
  min_top: number
  price_per_ton: number

  client: IClient | number
  stand: IStand | number
}
