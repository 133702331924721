import * as React from "react"
import { useCallback, useState } from "react"
import IProduct, { PRODUCT_ENDPOINT } from "../../../shared/models/core/IProduct"
import { Controller, useForm } from "react-hook-form"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowUpIcon from "@mui/icons-material/Cancel"
import KeyboardArrowDownIcon from "@mui/icons-material/Edit"
import { currency } from "../../../shared/utilities/formatters"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import DeleteIcon from "@mui/icons-material/Delete"
import IProductTemplate, { PRODUCT_TEMPLATE_ENDPOINT } from "../../../shared/models/core/IProductTemplate"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { useApiPaged } from "../../../shared/hooks/useApiPaged"

const ptRepository = new RestRepository<IProductTemplate>(PRODUCT_TEMPLATE_ENDPOINT)
const pRepository = new RestRepository<IProduct>(PRODUCT_ENDPOINT)

interface IProps {
  product: IProduct
  onChange: () => void
}

/**
 * This component displays and edits a product row.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ProductRow: React.FunctionComponent<IProps> = (props: IProps) => {
  const { product, onChange } = props
  const [open, setOpen] = useState(false)
  const { control, handleSubmit, setValue } = useForm<any>()

  const { data: productTemplates } = useApiPaged<IProductTemplate>({ apiFunction: ptRepository.findAll })
  const [currentProductTemplate, setCurrentProductTemplate] = useState<IProductTemplate | null>(null)

  const handleDeleteProduct = useCallback(async () => {
    setOpen(false)
    await pRepository.delete(product.id)
    onChange()
  }, [product])

  const handleUpdateProduct = useCallback(
    async (product1: IProduct) => {
      await pRepository.edit({ ...product, ...product1 }, product.id)
      onChange()
      setOpen(false)
    },
    [product]
  )

  const handleProductTemplateChange = useCallback((event: SelectChangeEvent<string | number>) => {
    const ptId = Number(event.target.value)
    const pt = productTemplates?.results?.filter(pt => pt.id === ptId)[0]
    if (pt !== undefined) {
      setCurrentProductTemplate(pt)
    }
  }, [])

  const handleApplyProductTemplate = useCallback(() => {
    if (currentProductTemplate !== null) {
      setValue("name", currentProductTemplate.name)
      setValue("min_length", currentProductTemplate.min_length)
      setValue("cut_to_length", currentProductTemplate.cut_to_length)

      setValue("min_butt", currentProductTemplate.min_butt)
      setValue("max_butt", currentProductTemplate.max_butt)

      setValue("min_dbh", currentProductTemplate.min_dbh)
      setValue("max_dbh", currentProductTemplate.max_dbh)

      setValue("min_top", currentProductTemplate.min_top)
      setValue("price_per_ton", currentProductTemplate.price_per_ton)
    }
  }, [currentProductTemplate])

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {product.name !== "" ? product.name : <em>(not set)</em>}
        </TableCell>
        <TableCell align="right">{product.min_length}</TableCell>
        <TableCell align="right">{product.cut_to_length}</TableCell>

        <TableCell align="right">{product.min_butt}</TableCell>
        <TableCell align="right">{product.max_butt}</TableCell>

        <TableCell align="right">{product.min_dbh}</TableCell>
        <TableCell align="right">{product.max_dbh}</TableCell>

        <TableCell align="right">{product.min_top}</TableCell>
        <TableCell align="right">{currency(product.price_per_ton)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <form onSubmit={handleSubmit(handleUpdateProduct)} autoComplete="off">
              <Box>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs />
                  <Grid item md={3} xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id={`product-template-select-label-${product.id}`}>Product Templates</InputLabel>
                      <Select
                        labelId={`product-template-select-label-${product.id}`}
                        id={`product-template-select-${product.id}`}
                        label="Product Templates"
                        value={currentProductTemplate !== null ? currentProductTemplate?.id : ""}
                        onChange={handleProductTemplateChange}
                      >
                        {productTemplates?.results?.map(pt => (
                          <MenuItem key={pt.id} value={pt.id}>
                            {pt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleApplyProductTemplate}>
                      Apply
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={5}>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={product.name !== "" ? product?.name : ""}
                      rules={{ required: { value: true, message: "Name is required." } }}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Name"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="min_length"
                      control={control}
                      defaultValue={product.min_length}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Min Length"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="min_top"
                      control={control}
                      defaultValue={product.min_top}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Min Top"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="cut_to_length"
                      control={control}
                      defaultValue={product.cut_to_length}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Cut To Length"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="min_butt"
                      control={control}
                      defaultValue={product.min_butt}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Min Butt"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="max_butt"
                      control={control}
                      defaultValue={product.max_butt}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Max Butt"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="min_dbh"
                      control={control}
                      defaultValue={product.min_dbh}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Min DBH"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="max_dbh"
                      control={control}
                      defaultValue={product.max_dbh}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Max DBH"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="price_per_ton"
                      control={control}
                      defaultValue={product.price_per_ton}
                      render={({ field, fieldState }) => (
                        <TextField
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          label="Price/Ton"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                  <Grid item xs>
                    <Button color="secondary" startIcon={<DeleteIcon />} onClick={handleDeleteProduct}>
                      Delete
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ProductRow
