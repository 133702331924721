import * as React from "react"
import { useEffect } from "react"
import { ThemeProvider } from "@mui/material"
import theme from "../themes/default_theme"
import { graphql, useStaticQuery } from "gatsby"

interface IProps {
  path?: any
  children?: any
  component?: any
  title?: string
}

/**
 * Layout for the login page.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const LoginLayout: React.FunctionComponent<IProps> = (props: IProps) => {
  const { children, component: Component, title } = props
  const data = useStaticQuery(
    graphql`
      query LoginQuery {
        site {
          siteMetadata {
            title
            version
          }
        }
      }
    `
  )

  useEffect(() => {
    const appTitle: string | undefined = data?.site?.siteMetadata?.title
    if (title !== undefined && typeof window !== "undefined" && appTitle !== undefined) {
      window.document.title = `${appTitle} | ${title}`
    }
  }, [title])

  return (
    <>
      <ThemeProvider theme={theme}>
        {children}
        {Component !== undefined && <Component {...props} data={data} />}
      </ThemeProvider>
    </>
  )
}

export default LoginLayout
