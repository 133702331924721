import {
  SETTINGS_SET_CURRENT_USER,
  SETTINGS_SET_FILTERS,
  SETTINGS_SET_ORDERING,
  SETTINGS_SET_PAGE,
  SETTINGS_SET_TITLE,
} from "./types"
import ICurrentUser from "../../shared/models/ICurrentUser"
import { IOrdering } from "../../shared/models/IOrdering"
import { IFilter } from "../../shared/models/IFilter"
import IAction from "../../shared/models/IAction"

/**
 * Build action to set the filters.
 *
 * @param {string} path of the page.
 * @param {IFilter[]} filters to set.
 * @returns {IAction} the action.
 */
export const setFilters = (path: string, filters: IFilter[]): IAction => ({
  type: SETTINGS_SET_FILTERS,
  payload: { path, filters, page: 1, offset: 0 },
})

/**
 * Build action to set the filters.
 *
 * @param {string} path of the page.
 * @param {IFilter[]} ordering to set.
 * @returns {IAction} the action.
 */
export const setOrdering = (path: string, ordering: IOrdering): IAction => ({
  type: SETTINGS_SET_ORDERING,
  payload: { path, ordering },
})

/**
 * Build action to set the page.
 *
 * @param {string} path of the page.
 * @param {number} page to set.
 * @param {number} offset to set.
 * @returns {IAction} the action.
 */
export const setPage = (path: string, page: number, offset: number): IAction => ({
  type: SETTINGS_SET_PAGE,
  payload: { path, page, offset },
})

/**
 * Build action to set the current user.
 *
 * @param {ICurrentUser | null} payload to set.
 * @returns {IAction} the action.
 */
export const setCurrentUser = (payload: ICurrentUser | null): IAction => ({
  type: SETTINGS_SET_CURRENT_USER,
  payload,
})

/**
 * Build action to set the title.
 *
 * @param {string} payload title of the page.
 * @returns {IAction} the action.
 */
export const setTitle = (payload: string): IAction => ({
  type: SETTINGS_SET_TITLE,
  payload,
})
