import { Dispatch } from "redux"
import { actions } from "./index"
import ICurrentUser from "../../shared/models/ICurrentUser"
import { IOrdering } from "../../shared/models/IOrdering"
import { IFilter } from "../../shared/models/IFilter"

/**
 * Set the filters for a page.
 *
 * @param {string} path of the page.
 * @param {IFilter[]} filters to set.
 * @returns {(dispatch: Dispatch) => void} the dispatch function
 */
export const setFilters = (path: string, filters: IFilter[]): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setFilters(path, filters))
  }
}

/**
 * Set the ordering for a page.
 *
 * @param {string} path of the page.
 * @param {IOrdering} ordering to set.
 * @returns {(dispatch: Dispatch) => void} the dispatch function
 */
export const setOrdering = (path: string, ordering: IOrdering): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setOrdering(path, ordering))
  }
}

/**
 * Set the current page number and offset for a page.
 *
 * @param {string} path of the page.
 * @param {number} page to set.
 * @param {offset} offset to set.
 * @returns {(dispatch: Dispatch) => void} the dispatch function
 */
export const setPage = (path: string, page: number, offset: number): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setPage(path, page, offset))
  }
}

/**
 * Set the title for the current page.
 *
 * @param {string} title of the page.
 * @returns {(dispatch: Dispatch) => void} the dispatch function
 */
export const setTitle = (title: string): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setTitle(title))
  }
}

/**
 * Clear the current user from the store.
 *
 * @returns {(dispatch: Dispatch) => void} the dispatch function
 */
export const clearCurrentUser = (): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setCurrentUser(null))
  }
}

/**
 * Set the current user logged in.
 *
 * @param {ICurrentUser} currentUser of the page.
 * @returns {(dispatch: Dispatch) => void} the dispatch function
 */
export const setCurrentUser = (currentUser: ICurrentUser): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setCurrentUser(currentUser))
  }
}
