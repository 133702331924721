import { Box } from "@mui/material"
import * as React from "react"
import { useCallback, useState } from "react"

interface IUseTabPanelProps {
  initialTab?: number
}

interface IUseTabPanelResponse {
  tab: number
  handleTabChange: (event: React.SyntheticEvent, newTab: number) => void
}

/**
 * This is a hook companion to the TabPanel which will manage the TabPanel state.
 *
 * @param {IUseTabPanelResponse} props see IUseTabPanelResponse for details.
 * @returns {IUseTabPanelResponse} the tab state.
 */
export const useTabPanel = (props?: IUseTabPanelProps): IUseTabPanelResponse => {
  const initialTab: number = props?.initialTab !== undefined ? props.initialTab : 0

  const [tab, setTab] = useState<number>(initialTab)

  const handleTabChange = useCallback((event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab)
  }, [])

  return { tab, handleTabChange }
}

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

/**
 * This component will display a tab panel.
 *
 * @param {ITabPanelProps} props see IProps for details.
 * @class
 */
const TabPanel: React.FunctionComponent<ITabPanelProps> = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </Box>
  )
}

export default TabPanel
