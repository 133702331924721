import * as React from "react"
import { useCallback, useEffect, useMemo } from "react"
import AppBar from "@mui/material/AppBar"
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "gatsby"
import ClientSelect from "../components/ClientSelect"
import { logout } from "../utilities/auth"
import NoClients from "../../pages/errors/NoClients"
import { operations } from "../../store/clients"
import { useDispatch, useSelector } from "react-redux"
import { IBooksStore } from "../../store"
import theme from "../themes/default_theme"
import useAuth from "../hooks/useAuth"
import { PRODUCT_TEMPLATES_URL, STANDS_URL } from "../../config/urls"
import { useLocation } from "@reach/router"

interface IProps {
  component: any
  data: any
}

/**
 * Main layout for displaying a page.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const MainLayout: React.FunctionComponent<IProps> = (props: IProps) => {
  const { component: Component, data } = props
  const dispatch = useDispatch()
  const client = useSelector((state: IBooksStore) => state.clients?.item)
  const { currentUser } = useAuth()

  const location = useLocation()
  const isSelected = useCallback((path: string) => location.pathname.startsWith(path), [location])

  const currentUserHasClients = useMemo(() => {
    if (currentUser?.user.profile.clients !== undefined) {
      return currentUser.user.profile.clients.length > 0
    }
    return false
  }, [currentUser])

  const handleLogout = useCallback(() => {
    logout()
  }, [])

  useEffect(() => {
    if (client !== undefined && client !== null && currentUser?.user.profile.clients !== undefined) {
      dispatch(operations.setClient(currentUser.user.profile.clients[0]))
    }
  }, [currentUser])

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }, [])

  const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }, [])

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null)
  }, [])

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null)
  }, [])

  const buttonStyle = useCallback(
    (selected: boolean) => ({
      ml: 1,
      my: 2,
      color: "white",
      display: "block",
      backgroundColor: selected ? "primary.light" : "inherit",
      "&:hover": {
        backgroundColor: "primary.light",
        borderColor: "primary.light",
        boxShadow: "none",
      },
    }),
    []
  )

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <AppBar position="fixed">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
                Books{" "}
                <Box sx={{ ml: 1 }}>
                  <small>by Firefly</small>
                </Box>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem>
                    <ClientSelect asListItem />
                  </MenuItem>
                  <MenuItem component={Link} selected={isSelected(STANDS_URL)} to={STANDS_URL}>
                    <Typography textAlign="center">Stands</Typography>
                  </MenuItem>
                  <MenuItem component={Link} selected={isSelected(PRODUCT_TEMPLATES_URL)} to={PRODUCT_TEMPLATES_URL}>
                    <Typography textAlign="center">Product Templates</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                Books
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <ClientSelect />
                <Button
                  component={Link}
                  to={STANDS_URL}
                  variant="contained"
                  disableElevation={true}
                  sx={buttonStyle(isSelected(STANDS_URL))}
                >
                  Stands
                </Button>
                <Button
                  component={Link}
                  to={PRODUCT_TEMPLATES_URL}
                  variant="contained"
                  disableElevation={true}
                  sx={buttonStyle(isSelected(PRODUCT_TEMPLATES_URL))}
                >
                  Product Templates
                </Button>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={currentUser !== null ? currentUser.user.first_name : "User Not Logged In"}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={currentUser?.user.first_name} sx={{ backgroundColor: "secondary.main" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: 6 }}
                  keepMounted
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar />

        <Container sx={{ mt: 3 }}>{currentUserHasClients ? <Component /> : <NoClients />}</Container>

        <Toolbar />
        <AppBar position="fixed" color="default" sx={{ top: "auto", bottom: 0 }}>
          <Toolbar variant="dense">
            © Firefly Inventory {new Date().getFullYear()} | release: {data.site.siteMetadata.version}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  )
}

export default MainLayout
