import * as React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../app/shared/components/PrivateRoute"
import MainLayout from "../app/shared/layouts/MainLayout"
import { Provider, shallowEqual, useSelector } from "react-redux"
import store, { IBooksStore } from "../app/store"

import "mapbox-gl/dist/mapbox-gl.css"

import Stands from "../app/pages/stands/Index"
import StandView from "../app/pages/stands/View"
import StandEdit from "../app/pages/stands/Edit"
import StandProducts from "../app/pages/stands/Products"
import StandAdd from "../app/pages/stands/Add"
import ProductTemplates from "../app/pages/product_templates/Index"
import ProductTemplateAdd from "../app/pages/product_templates/Add"
import ProductTemplateEdit from "../app/pages/product_templates/Edit"
import ClientView from "../app/pages/cruises/View"

import { Helmet } from "react-helmet"
import { CssBaseline, GlobalStyles } from "@mui/material"
import { graphql } from "gatsby"
import NotFound from "./404"
import LoginLayout from "../app/shared/layouts/LoginLayout"
import LoginPage from "../app/pages/auth/Login"
import {
  CONSOLE_LOGIN_URL,
  CRUISES_VIEW_URL,
  LOGIN_URL,
  PRODUCT_TEMPLATES_ADD_URL,
  PRODUCT_TEMPLATES_EDIT_URL,
  PRODUCT_TEMPLATES_URL,
  RIVER_LOGIN_URL,
  SHEPHERD_LOGIN_URL,
  STANDS_ADD_URL,
  STANDS_EDIT_URL,
  STANDS_URL,
  STANDS_VIEW_URL,
} from "../app/config/urls"

interface IProps {
  data: any
}

/**
 * Main app wrapper and entry point for books.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const App: React.FunctionComponent<IProps> = (props: IProps) => {
  const { data } = props
  const { title } = useSelector((state: IBooksStore) => ({ ...state.settings }), shallowEqual)

  return (
    <>
      <Helmet>
        <title>
          {data.site.siteMetadata.title} | {title}
        </title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <CssBaseline />
      <GlobalStyles styles={{ body: { backgroundColor: "#f9f9f9" } }} />
      <Router>
        <LoginLayout path="/" title="Login" component={LoginPage} />
        <LoginLayout path={LOGIN_URL} title="Login" component={LoginPage} />
        <LoginLayout path={RIVER_LOGIN_URL} title="River Login" component={LoginPage} />
        <LoginLayout path={SHEPHERD_LOGIN_URL} title="Shepherd Login" component={LoginPage} />
        <LoginLayout path={CONSOLE_LOGIN_URL} title="Console Login" component={LoginPage} />
        <PrivateRoute path={STANDS_URL} component={() => <MainLayout data={data} component={Stands} />} />
        <PrivateRoute path={STANDS_ADD_URL} component={() => <MainLayout data={data} component={StandAdd} />} />
        <PrivateRoute
          path={`${STANDS_VIEW_URL}/:id`}
          component={() => <MainLayout data={data} component={StandView} />}
        />
        <PrivateRoute
          path={`${STANDS_EDIT_URL}/:id`}
          component={() => <MainLayout data={data} component={StandEdit} />}
        />
        <PrivateRoute
          path={`${STANDS_VIEW_URL}/:id/products`}
          component={() => <MainLayout data={data} component={StandProducts} />}
        />
        <PrivateRoute
          path={PRODUCT_TEMPLATES_URL}
          component={() => <MainLayout data={data} component={ProductTemplates} />}
        />
        <PrivateRoute
          path={PRODUCT_TEMPLATES_ADD_URL}
          component={() => <MainLayout data={data} component={ProductTemplateAdd} />}
        />
        <PrivateRoute
          path={`${PRODUCT_TEMPLATES_EDIT_URL}/:id`}
          component={() => <MainLayout data={data} component={ProductTemplateEdit} />}
        />
        <PrivateRoute
          path={`${CRUISES_VIEW_URL}/:id`}
          component={() => <MainLayout data={data} component={ClientView} />}
        />
        <NotFound default data={data} />
      </Router>
    </>
  )
}

/**
 * Wrapper for the data store.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const AppWrapper: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <Provider store={store.store}>
        <App {...props} />
      </Provider>
    </>
  )
}

export default AppWrapper

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        version
      }
    }
  }
`
