import IToken from "../models/IToken"
import { BASE_URL, getParams } from "../utilities/request_utility"
import IUser from "../models/IUser"
import axios from "axios"

/**
 * Get the current login user details.
 *
 * @param {IToken} token to get user information.
 * @returns {Promise<IUser>} the logged in user.
 */
export const me = async (token: IToken): Promise<IUser> => {
  const { data } = await axios.get(`${BASE_URL}/users/me/`, getParams(token))
  return data[0]
}
