import { FIND_ITEM } from "./types"
import IClient from "../../shared/models/core/IClient"
import IAction from "../../shared/models/IAction"

/**
 * Build an action with the item which is a client.
 *
 * @param {IClient} payload to set.
 * @returns {IAction} that is built.
 */
export const setItem = (payload: IClient): IAction => ({
  type: FIND_ITEM,
  payload,
})
