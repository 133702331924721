export const DATE_FORMAT = "MMMM Do YYYY"

export const METER_TO_INCH = 39.37008

/**
 * Format a date string.
 *
 * @param {string} dateString ISO format.
 * @returns {string} formatted date
 */
export const formatDate = (dateString: string): string => {
  return dateString // moment(dateString).format(DATE_FORMAT)
}

/**
 * Get the age given the year.
 *
 * @param {number} year just the year.
 * @returns {number} the age.
 */
export const getAge = (year: number): number => {
  return new Date().getFullYear() - year
}

/**
 * Convert square meters to acres.
 *
 * @param {number} area to convert.
 * @returns {string} formatted acres.
 */
export const squareMetersToAcres = (area: number): string => {
  return (area * 0.0002471052).toFixed(2)
}

/**
 * Convert meters^2 to inches^2.
 *
 * @param {number} area to convert.
 * @returns {string} the converted value.
 */
export const squareMetersToSquareInches = (area: number): string => {
  return (area * 1550).toFixed() + '"'
}

/**
 * Convert meters to inches.
 *
 * @param {number} length to convert in meters
 * @param {boolean} naIfEmpty set as false to show 0.00".
 * @param {boolean} valueOnly set as true to just return the number.
 * @returns {string|number} formatted value in inches.
 */
export const metersToInches = (
  length: number,
  naIfEmpty: boolean = true,
  valueOnly: boolean = false
): string | number => {
  if ((length === 0 || length === null) && naIfEmpty) {
    return "----"
  }
  if (valueOnly) {
    return parseFloat((length * METER_TO_INCH).toFixed(2))
  }
  return (length * METER_TO_INCH).toFixed(2) + '"'
}

/**
 * Format a number to currency.
 *
 * @param {number|string} amount that needs formatting.
 * @returns {string} the formatted number.
 */
export const currency = (amount: number | string): string => {
  if (amount === null) {
    return "----"
  }
  return `$${Number(amount).toFixed(2)}`
}
