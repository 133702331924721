import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { Link as RouterLink } from "@reach/router"
import { squareMetersToAcres } from "../../shared/utilities/formatters"
import Link from "@mui/material/Link"
import { useApiRead } from "../../shared/hooks/useApiRead"
import { RestRepository } from "../../shared/repositories/RestRepository"
import ICruise, { CRUISES_ENDPOINT } from "../../shared/models/core/ICruise"
import { CRUISES_VIEW_URL, STANDS_VIEW_URL } from "../../config/urls"
import Map from "../../shared/components/Map"
import FormatDate from "../../shared/components/format/FormatDate"
import ViewLoading from "../../shared/components/ViewLoading"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import ProductsTable from "./components/ProductsTable"
import TreesViewer from "./components/TreesViewer"
import { IPlot } from "../../shared/models/core/IPlot"
import { IPagedResults } from "../../shared/models/IPagedResults"
import ITree from "../../shared/models/core/ITree"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { IConnectionError } from "../../shared/models/IConnectionError"

const cruiseRepository = new RestRepository<ICruise>(CRUISES_ENDPOINT)
const cruiseTreesRepository = new RestRepository<IPagedResults<IPlot>>(CRUISES_ENDPOINT)

/**
 * Page for viewing a cruise.
 *
 * @class
 */
const View: React.FunctionComponent = () => {
  const { data: cruise, loading } = useApiRead<ICruise>({ apiFunction: cruiseRepository.read })
  const [selectedPlot, setSelectedPlot] = useState<IPlot | null>(null)
  const [plotResults, setPlotResults] = useState<IPagedResults<IPlot> | null>(null)
  const { tab, handleTabChange } = useTabPanel()
  const [loadingTrees, setLoadingTrees] = useState<boolean>(false)
  const [error, setError] = useState<IConnectionError | null>(null)

  const handlePlotChange = useCallback(
    (event: SelectChangeEvent<number | undefined>) => {
      const plotId = event.target.value
      const plots = cruise?.plots.filter((plot: IPlot) => plot.id === plotId)
      if (plots !== undefined && plots.length > 0) {
        setSelectedPlot(plots[0])
      }
    },
    [cruise]
  )

  const treesInPlot: ITree[] | null = useMemo(() => {
    const plots = plotResults?.results.filter(plot => plot.id === selectedPlot?.id)
    if (plots !== undefined && plots.length > 0 && plots[0].trees !== undefined) {
      return plots[0].trees
    }
    return null
  }, [plotResults, selectedPlot])

  const plotsToUse: IPlot[] | undefined = useMemo(() => {
    if (plotResults !== null && plotResults.results.length > 0) {
      return plotResults.results
    }
    return cruise?.plots
  }, [plotResults, cruise])

  useEffect(() => {
    if (plotsToUse !== undefined && plotsToUse.length > 0 && selectedPlot === null) {
      setSelectedPlot(plotsToUse[0])
    }
  }, [cruise])

  useEffect(() => {
    void (async function () {
      if (cruise !== null) {
        setLoadingTrees(true)
        try {
          const treeIndexes = await cruiseTreesRepository.action(cruise.id, "trees_index")
          setPlotResults(treeIndexes)
          if (treeIndexes.results.length > 0) {
            setSelectedPlot(treeIndexes.results[0])
          }
        } catch (reason: any) {
          setError(reason)
        }
        setLoadingTrees(false)
      }
    })()
  }, [cruise])

  return (
    <>
      <ViewLoading loading={loading || loadingTrees} />
      <ErrorMessage error={error} />
      {cruise !== null && (
        <>
          <Grid container spacing={2} alignItems="baseline" sx={{ mb: 2 }}>
            <Grid item xs>
              <Link component={RouterLink} to={`${CRUISES_VIEW_URL}/${cruise.id}`}>
                <Typography variant="h2" component="h2">
                  Cruise {cruise.id}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Stand
                          </TableCell>
                          <TableCell align="right">
                            {`${cruise.stand.id} | `}
                            <Link component={RouterLink} to={`${STANDS_VIEW_URL}/${cruise.stand?.id}`}>
                              {cruise.stand?.name}
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Cruise ID
                          </TableCell>
                          <TableCell align="right">{cruise.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Area
                          </TableCell>
                          <TableCell align="right">{squareMetersToAcres(cruise.stand.area)} acres</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Coverage
                          </TableCell>
                          <TableCell align="right">empty</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Carrier
                          </TableCell>
                          <TableCell align="right">empty</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Trees Found
                          </TableCell>
                          <TableCell align="right">{cruise.tree_count}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Date
                          </TableCell>
                          <TableCell align="right">
                            <FormatDate value={cruise.cruise_date} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Paper>
                <Map
                  shape={cruise.stand.shape}
                  centroid={cruise.stand.centroid}
                  plots={plotsToUse}
                  width={cruise.plot_shape_width}
                  selectedPlot={selectedPlot}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Paper>
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs>
                    <Typography variant="h5">Results</Typography>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel>Plots</InputLabel>
                      <Select
                        value={selectedPlot !== null ? selectedPlot.id : 1}
                        label="Plot"
                        onChange={handlePlotChange}
                      >
                        {plotsToUse?.map((plot: IPlot) => (
                          <MenuItem key={plot.id} value={plot.id}>
                            Plot {plot.id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Trees" />
                  <Tab label="Products" />
                </Tabs>
                <TabPanel index={tab} value={0}>
                  <TreesViewer cruise={cruise} trees={treesInPlot} />
                </TabPanel>
                <TabPanel index={tab} value={1}>
                  <ProductsTable cruise={cruise} />
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default View
