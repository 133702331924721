import { setItem } from "./actions"
import { Dispatch } from "redux"
import IClient from "../../shared/models/core/IClient"

/**
 * Operation to set the client in the global store.
 *
 * @param {IClient} client to set.
 * @returns {(dispatch: Dispatch) => void} a dispatch to run.
 */
export const setClient = (client: IClient): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(setItem(client))
  }
}
