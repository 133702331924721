export const mapbox = {
  linePaint: {
    "line-color": "#4790E5",
    "line-width": 4,
  },
  plotPaint: {
    "fill-color": "blue",
    "fill-opacity": 0.6,
  },
}

export const styles = {
  tableSelect: {
    cursor: "pointer",
  },
}
