import * as React from "react"
import Moment from "react-moment"
import { DATE_FORMAT } from "../../../config/config"

interface IProps {
  value?: string | null
}

/**
 * Component for displaying a date.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const FormatDate: React.FunctionComponent<IProps> = (props: IProps) => {
  const { value } = props

  return <>{value !== undefined && value !== null ? <Moment format={DATE_FORMAT}>{value}</Moment> : <>Not Set</>}</>
}

export default FormatDate
