import { FIND_ITEM } from "./types"
import IClient from "../../shared/models/core/IClient"
import IAction from "../../shared/models/IAction"
import IClientsState from "./models/IClientsState"

const initialState: IClientsState = {
  item: null,
}

/**
 * Sets the client for the state.
 *
 * @param {IClientsState} state current.
 * @param {IClient} item to set.
 * @returns {IClientsState} updated state.
 */
const setItem = (state: IClientsState, item: IClient): IClientsState => ({
  ...state,
  item,
})

/**
 * Reducer for managing the redux store client state.
 *
 * @param {IClientsState} state the current state.
 * @param {IAction} action to adjust the state.
 * @returns {IClientsState} update state
 */
export default (state: IClientsState = initialState, action: IAction): IClientsState => {
  switch (action.type) {
    case FIND_ITEM: {
      return setItem(state, action.payload)
    }
  }
  return state
}
