// noinspection SpellCheckingInspection

import * as React from "react"
import { useEffect, useRef, useState } from "react"
import * as mapboxgl from "mapbox-gl"
import { Box } from "@mui/material"
import { IPlot } from "../models/core/IPlot"

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

/**
 * Get the bounds of the stand.
 *
 * @param {number[][]} shape of the stand.
 * @returns {number[][]} the bounds of the shape
 */
const getShapeBounds = (shape: number[][]): number[][] => {
  let minLng = 0
  let maxLng = 0

  let minLat = 0
  let maxLat = 0
  for (const c of shape) {
    if (minLng === 0) {
      minLng = c[0]
      minLat = c[1]

      maxLng = c[0]
      maxLat = c[1]
    }

    if (minLng > c[0]) {
      minLng = c[0]
    }
    if (minLat > c[1]) {
      minLat = c[1]
    }
    if (maxLng < c[0]) {
      maxLng = c[0]
    }
    if (maxLat < c[1]) {
      maxLat = c[1]
    }
  }

  return [
    [minLng, maxLat],
    [maxLng, maxLat],
    [maxLng, minLat],
    [minLng, minLat],
    [minLng, maxLat],
  ]
}

/**
 * Create a GeoJSON circle.
 *
 * @param {number[]} center long and lat of the circle.
 * @param {number} radiusInKm for the circle.
 * @param {number} points the circle will contain.
 * @returns {any} the circle as a polygon.
 */
const createGeoJSONCircle = (center: number[], radiusInKm: number, points: number = 64): any => {
  const coords = { latitude: center[1], longitude: center[0] }

  const km = radiusInKm

  const ret = []
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180))
  const distanceY = km / 110.574

  let theta, x, y
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI)
    x = distanceX * Math.cos(theta)
    y = distanceY * Math.sin(theta)

    ret.push([coords.longitude + x, coords.latitude + y])
  }
  // connect the polygon to the beginning.
  ret.push(ret[0])

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [ret],
    },
  }
}

/**
 * Add plot shapes to map.
 *
 * @param {mapboxgl.Map} map to add shapes to.
 * @param {IPlot[] | null} plots to add to the map.
 * @param {number} width of the plot.
 */
const addPlotShapes = (map: mapboxgl.Map, plots: IPlot[] | null, width: number): void => {
  if (map.getSource("source-plot-shapes") !== undefined) {
    map.removeLayer("layer-plot-shapes")
    map.removeSource("source-plot-shapes")
  }
  if (plots !== null) {
    const radius = width / 2 / 1000

    const features = plots.map(plot => ({
      ...createGeoJSONCircle([plot.longitude, plot.latitude], radius),
    }))
    map.addSource("source-plot-shapes", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features,
      },
    })
    map.addLayer({
      id: "layer-plot-shapes",
      type: "fill",
      source: "source-plot-shapes",
      paint: {
        "fill-color": "blue",
        "fill-opacity": 0.6,
      },
    })
  }
}

/**
 * Add plot markers to the map.
 *
 * @param {mapboxgl.Map} map to add markers to.
 * @param {IPlot[] | null} plots to add to the map.
 * @param {IPlot | null} selectedPlot current plot.
 * @returns {mapboxgl.Marker[]} the markers add to the map.
 */
const addPlots = (map: mapboxgl.Map, plots: IPlot[], selectedPlot: IPlot | null): mapboxgl.Marker[] => {
  if (plots.length === 0 || plots[0] === null) {
    return []
  }
  const markers = []
  for (const plot of plots) {
    const options: mapboxgl.MarkerOptions = { color: selectedPlot?.id === plot.id ? "#3FB1CE" : "#444" }
    const marker = new mapboxgl.Marker(options).setLngLat([plot.longitude, plot.latitude]).addTo(map)
    markers.push(marker)
  }
  return markers
}

/**
 * Add tree markers to the map.
 *
 * @param {mapboxgl.Map} map to add markers to.
 * @param {IPlot[] | null} plots to add to the map.
 * @returns {mapboxgl.Marker[]} the markers add to the map.
 */
const addTrees = (map: mapboxgl.Map, plots: IPlot[]): mapboxgl.Marker[] => {
  if (plots.length === 0 || plots[0] === null) {
    return []
  }
  const trees = []
  const options: mapboxgl.MarkerOptions = { color: "#5dce2a", scale: 0.5 }
  for (const plot of plots) {
    if (plot.trees !== undefined) {
      for (const tree of plot.trees) {
        const marker = new mapboxgl.Marker(options).setLngLat([tree.longitude, tree.latitude]).addTo(map)
        trees.push(marker)
      }
    }
  }
  return trees
}

interface IProps {
  shape?: [[number, number]]
  centroid?: [number, number]
  plots?: IPlot[]
  width?: number
  selectedPlot?: IPlot | null
}

const fbOptions = { padding: { top: 30, bottom: 30, left: 30, right: 30 } }

/**
 * Renders the map with the stand, plots and trees.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const Map: React.FunctionComponent<IProps> = (props: IProps) => {
  const { centroid, shape, plots = null, width = null, selectedPlot = null } = props
  const mapContainer = useRef(null)
  const [map, setMap] = useState<mapboxgl.Map | null>(null)
  const [plotMarkers, setPlotMarkers] = useState<mapboxgl.Marker[] | null>(null)
  const [treeMarkers, setTreeMarkers] = useState<mapboxgl.Marker[] | null>(null)

  useEffect(() => {
    if (
      mapContainer?.current !== null &&
      mapContainer?.current !== undefined &&
      map === null &&
      centroid !== null &&
      centroid !== undefined &&
      shape !== undefined
    ) {
      const theMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v9",
        center: centroid,
        accessToken: MAPBOX_TOKEN,
      })
      theMap.on("load", () => {
        new mapboxgl.Marker({ color: "#e54764" }).setLngLat(centroid).addTo(theMap)
        const bounds = getShapeBounds(shape)
        const upperCorner = new mapboxgl.LngLat(bounds[0][0], bounds[0][1])
        const lowerCorner = new mapboxgl.LngLat(bounds[2][0], bounds[2][1])
        theMap.fitBounds(new mapboxgl.LngLatBounds([upperCorner, lowerCorner]), fbOptions)
        theMap.addSource("stand", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: shape,
            },
          },
        })
        theMap.addLayer({
          id: "stand",
          type: "line",
          source: "stand",
          paint: {
            "line-color": "#4790E5",
            "line-width": 4,
          },
        })
        setMap(theMap)
      })
    }
  }, [mapContainer.current, map, shape, centroid, plots, width])

  useEffect(() => {
    if (map !== null && plots !== null) {
      if (selectedPlot !== null && selectedPlot !== undefined && width !== null) {
        addPlotShapes(map, plots, width)

        treeMarkers?.forEach(marker => marker.remove())
        const markers = addTrees(map, plots)
        setTreeMarkers(markers)
      }
    }
  }, [map, plots])

  useEffect(() => {
    if (map !== null && plots !== null) {
      plotMarkers?.forEach(marker => marker.remove())
      const markers = addPlots(map, plots, selectedPlot)
      setPlotMarkers(markers)

      if (selectedPlot !== null && selectedPlot !== undefined && width !== null) {
        const selectPlotShape = createGeoJSONCircle([selectedPlot.longitude, selectedPlot.latitude], width / 2 / 1000)
        const bounds = getShapeBounds(selectPlotShape.geometry.coordinates[0])
        const upperCorner = new mapboxgl.LngLat(bounds[0][0], bounds[0][1])
        const lowerCorner = new mapboxgl.LngLat(bounds[2][0], bounds[2][1])
        map.fitBounds(new mapboxgl.LngLatBounds([upperCorner, lowerCorner]), fbOptions)
      }
    }
  }, [map, plots, selectedPlot])

  return (
    <>
      <Box ref={mapContainer} sx={{ height: 400 }} />
    </>
  )
}

export default Map
