import { Box, Grid, Pagination } from "@mui/material"
import * as React from "react"
import { ChangeEvent, memo } from "react"

interface IProps {
  count: number
  page?: number
  total?: number
  handlePaging: (_e: ChangeEvent<unknown>, page: number) => void
}

const numFormatOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }

/**
 * This component will be used for paging through data.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const TablePaging: React.FunctionComponent<IProps> = (props: IProps) => {
  const { count, page, total, handlePaging } = props
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        <Box sx={{ p: 2 }}>
          <Pagination count={count} page={page} onChange={handlePaging} size="large" />
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ p: 2, mr: 1 }}>
          {total != null && <>Results: {total.toLocaleString(undefined, numFormatOptions)}</>}
        </Box>
      </Grid>
    </Grid>
  )
}

export default memo(TablePaging)
