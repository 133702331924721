import * as React from "react"
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Alert, Box, Button, CircularProgress, Container, Grid, Paper, TextField, Typography } from "@mui/material"
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login"
import { CONSOLE_LOGIN_URL, LOGIN_URL, RIVER_LOGIN_URL, SHEPHERD_LOGIN_URL, STANDS_URL } from "../../config/urls"
import useAuth from "../../shared/hooks/useAuth"
import { operations } from "../../store/settings"
import { useDispatch } from "react-redux"
import { useWindowResize } from "beautiful-react-hooks"
import FireflyIcon from "../../assets/images/FireflyIcon"
import { useLocation, useNavigate } from "@reach/router"

const GATSBY_GOOGLE_AUTH_TOKEN: string | undefined = process.env.GATSBY_GOOGLE_AUTH_TOKEN
const GATSBY_SHOW_USERNAME_PASSWORD_FORM: boolean = process.env.GATSBY_SHOW_USERNAME_PASSWORD_FORM === "true"

interface IProps {
  data: any
  path: string
}

/**
 * Main login page for the clients. It can handle google and basic authentication.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const LoginPage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { data } = props
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [opening, setOpening] = useState(false)
  const [googleToken, setGoogleToken] = useState<string | null>(null)
  const [initialLoad, setInitialLoad] = useState(true)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { loginWithToken, loginWithCredentials } = useAuth()

  const [windowHeight, setWindowHeight] = useState(
    typeof window !== "undefined" && window !== undefined ? window.innerHeight : 600
  )

  useWindowResize(() => setWindowHeight(window.innerHeight))

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      if (loginWithCredentials !== null) {
        e.preventDefault()
        setError(false)
        await loginWithCredentials({ username, password })
        await navigate(STANDS_URL)
      }
    },
    [loginWithCredentials]
  )

  const startLogin = useCallback(() => {
    setError(false)
    setLoading(true)
  }, [])

  const responseGoogleSuccess = useCallback(
    async (response: GoogleLoginResponse | GoogleLoginResponseOffline | any) => {
      if (loginWithToken !== null) {
        try {
          const accessToken: string = response.accessToken
          await loginWithToken(response.accessToken)
          if (location.pathname === SHEPHERD_LOGIN_URL) {
            setOpening(true)
            window.location.href = `firefly-shepherd://shepherd/login?token=${accessToken}`
          }
          if (location.pathname === RIVER_LOGIN_URL) {
            window.location.href = `firefly-river://river/login?token=${accessToken}`
            setOpening(true)
          }
          if (location.pathname === CONSOLE_LOGIN_URL) {
            setOpening(true)
            setGoogleToken(response.accessToken)
          }
          if (location.pathname === LOGIN_URL) {
            console.log("logging in")
            window.location.href = STANDS_URL
            setLoading(false)
          }
        } catch (e) {
          setError(true)
          setLoading(false)
        }
      }
    },
    [loginWithToken]
  )

  const responseGoogleFailure = useCallback(async () => {
    setError(true)
    setLoading(false)
  }, [])

  const title = useMemo(() => {
    if (location.pathname === SHEPHERD_LOGIN_URL) {
      return "Shepherd"
    }
    if (location.pathname === RIVER_LOGIN_URL) {
      return "River"
    }
    if (location.pathname === CONSOLE_LOGIN_URL) {
      return "Console"
    }
    return data.site.siteMetadata.title
  }, [location])

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
      dispatch(operations.clearCurrentUser())
    }
  }, [initialLoad])

  return (
    <Box>
      <Container>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: windowHeight }}>
          <Grid item lg={5} md={6} sm={9} xs={12}>
            <Paper variant="elevation" sx={{ p: 2 }}>
              <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
                {title} Login
              </Typography>
              <Box sx={{ width: 200, m: "auto", mt: 4, mb: 4 }}>
                <FireflyIcon />
              </Box>
              {loading && (
                <Grid container sx={{ p: 10 }}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      {!opening ? (
                        <>
                          <Grid item>
                            <CircularProgress size="1rem" />
                          </Grid>
                          <Grid item>Logging in...</Grid>
                        </>
                      ) : location.pathname === CONSOLE_LOGIN_URL ? (
                        <>
                          <Grid item xs={12}>
                            <TextField fullWidth value={googleToken} label="Google Token" />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item>Opening {title}...</Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {GATSBY_GOOGLE_AUTH_TOKEN !== undefined && !loading && (
                <Grid container justifyContent="center" sx={{ pt: 0, pr: 5, pl: 5, pb: 7 }}>
                  {error && (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Alert severity="error">Problem logging in.</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <GoogleLogin
                      onRequest={startLogin}
                      clientId={GATSBY_GOOGLE_AUTH_TOKEN}
                      buttonText="Login with Google"
                      onSuccess={responseGoogleSuccess}
                      onFailure={responseGoogleFailure}
                      cookiePolicy={"none"}
                      render={props => (
                        <Button {...props} color="secondary" variant="contained" fullWidth>
                          Login
                        </Button>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
              )}

              <form method="post" onSubmit={handleSubmit}>
                <Grid container>
                  {(GATSBY_SHOW_USERNAME_PASSWORD_FORM || GATSBY_GOOGLE_AUTH_TOKEN === null) && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Username"
                          variant="outlined"
                          name="username"
                          value={username}
                          fullWidth
                          onChange={e => setUsername(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          type="password"
                          variant="outlined"
                          name="password"
                          value={password}
                          fullWidth
                          onChange={e => setPassword(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button color="secondary" size="large" variant="contained" type="submit">
                          Login
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>

              <Typography>
                © {data.site.siteMetadata.company} {new Date().getFullYear()} | release:{" "}
                {data.site.siteMetadata.version}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default LoginPage
