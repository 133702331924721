export const LOGIN_URL = "/app/login"
export const SHEPHERD_LOGIN_URL = "/app/shepherd/login"
export const CONSOLE_LOGIN_URL = "/app/console/login"
export const RIVER_LOGIN_URL = "/app/river/login"

export const STANDS_URL = "/app/stands"
export const STANDS_ADD_URL = "/app/stands/add"
export const STANDS_EDIT_URL = "/app/stands/edit"
export const STANDS_VIEW_URL = "/app/stands/view"

export const CRUISES_VIEW_URL = "/app/cruises/view"

export const PRODUCT_TEMPLATES_URL = "/app/product_templates"
export const PRODUCT_TEMPLATES_ADD_URL = "/app/product_templates/add"
export const PRODUCT_TEMPLATES_EDIT_URL = "/app/product_templates/edit"

export const REPORTS_URL = "/app/reports"
export const SETTINGS_URL = "/app/settings"
