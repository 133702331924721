import * as React from "react"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import ICruise from "../../../shared/models/core/ICruise"
import ITree from "../../../shared/models/core/ITree"
import { metersToInches, squareMetersToSquareInches } from "../../../shared/utilities/formatters"

interface IProps {
  cruise: ICruise
  trees: ITree[] | null
}

/**
 * Displays a table of trees found during the cruise.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const TreesViewer: React.FunctionComponent<IProps> = (props: IProps) => {
  const { trees } = props
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tree ID</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Height</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Btm Dia</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Btm Area</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Top Dia</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Top Area</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(trees?.length === 0 || trees === null) && (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  No trees found in plot.
                </TableCell>
              </TableRow>
            )}

            {trees?.map(tree => (
              <TableRow key={tree.index}>
                <TableCell component="th" scope="row">
                  {tree.index}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: "right" }}>
                  {metersToInches(tree.height)}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: "right" }}>
                  {metersToInches(tree.bottom_cluster.average_radius * 2)}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: "right" }}>
                  {squareMetersToSquareInches(tree.bottom_cluster.average_area)}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: "right" }}>
                  {metersToInches(tree.top_cluster.average_radius * 2)}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: "right" }}>
                  {squareMetersToSquareInches(tree.top_cluster.average_area)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TreesViewer
