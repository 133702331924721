import IToken from "../models/IToken"
import { AxiosRequestConfig } from "axios"
import { IPaging } from "../models/IPaging"

export const BASE_URL: string = process.env.GATSBY_BASE_URL !== undefined ? process.env.GATSBY_BASE_URL : ""

/**
 * Add the access token to the header
 *
 * @param {IToken} token to put in the header.
 * @returns {AxiosRequestConfig} an axios request header.
 */
export const getParams = (token: IToken): AxiosRequestConfig => ({
  headers: {
    Authorization: `Bearer ${token.access}`,
  },
})

/**
 * Construct an url search query give paging.
 *
 * @param {IPaging} paging to build the query from.
 * @returns {string} the url query string.
 */
export const urlSearchParams = (paging?: IPaging): string => {
  if (paging === undefined) {
    return ""
  }
  const { limit, offset, ordering, filters } = paging
  let queryString = [] as string[]
  if (limit !== undefined) {
    queryString.push(`limit=${limit}`)
  }

  if (offset !== undefined) {
    queryString.push(`offset=${offset}`)
  }

  const filter = filters?.map(filter => `${filter.field}=${filter.value !== undefined ? filter.value : ""}`)
  if (filter != null) {
    queryString = queryString.concat(filter)
  }

  const order = ordering != null ? (ordering?.direction === "asc" ? ordering?.field : `-${ordering?.field}`) : null
  if (order !== undefined && order !== null) {
    queryString.push(`ordering=${order}`)
  }

  if (queryString.length > 0) {
    return `?${queryString.join("&")}`
  }
  return ""
}
