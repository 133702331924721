import * as React from "react"
import { useCallback, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import List from "@mui/material/List"
import ListItemText from "@mui/material/ListItemText"
import DialogActions from "@mui/material/DialogActions"
import { Button, ListItemButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { IBooksStore } from "../../store"
import { operations } from "../../store/clients"
import IClient from "../models/core/IClient"
import useAuth from "../hooks/useAuth"

interface IProps {
  asListItem?: boolean
}

/**
 * Also a component for selecting a client.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ClientSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { asListItem = false } = props

  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const client = useSelector((state: IBooksStore) => state.clients.item)
  const { currentUser } = useAuth()

  const setCurrentClient = useCallback((client1: IClient) => {
    dispatch(operations.setClient(client1))
    setOpen(false)
  }, [])

  return (
    <>
      {asListItem ? (
        <ListItemButton onClick={() => setOpen(true)}>
          <ListItemText primary={client != null ? client.name : "Not Selected"} secondary="Client" />
        </ListItemButton>
      ) : (
        <Button onClick={() => setOpen(true)} sx={{ my: 2, color: "white", display: "block" }}>
          {client != null ? client.name : "Not Selected"}
        </Button>
      )}

      <Dialog onClose={() => setOpen(false)} fullWidth maxWidth="sm" open={open}>
        <DialogTitle>Select A Client</DialogTitle>
        <DialogContent>
          <List>
            {currentUser?.user.profile.clients?.map((c, i) => (
              <ListItemButton key={i} selected={c.id === client?.id} onClick={() => setCurrentClient(c)}>
                <ListItemText primary={c.name} />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ClientSelect
