import useAuth from "./useAuth"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "@reach/router"
import { IConnectionError, isConnectionError } from "../models/IConnectionError"

interface IUseApiReadResponse<T> {
  call: () => void
  loading: boolean
  data: T | null
  error: IConnectionError | null
}

export interface IUseApiReadProps<T> {
  apiFunction: (id: string | number) => Promise<T | IConnectionError>
  dontCallOnMount?: boolean
}

/**
 * This hook helps with the reading of a specific value from the api.
 *
 * @param {IUseApiReadProps} props see IUseApiReadProps for details.
 * @returns {IUseApiReadResponse} the response and state.
 */
export const useApiRead = <T>(props: IUseApiReadProps<T>): IUseApiReadResponse<T> => {
  const { apiFunction, dontCallOnMount } = props
  const { logoutWithConnectionError } = useAuth()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)

  const call = useCallback(() => {
    setLoading(true)
    apiFunction(id)
      .then(results => {
        if (isConnectionError(results)) {
          setData(null)
          setError(results)
        } else {
          setData(results)
          setError(null)
        }
      })
      .catch(reason => {
        if (isConnectionError(reason)) {
          setData(null)
          setError(reason)
        } else {
          if (logoutWithConnectionError !== null) {
            logoutWithConnectionError(reason)
          }
        }
      })
      .finally(() => setLoading(false))
  }, [apiFunction, id, logoutWithConnectionError])

  useEffect(() => {
    if (dontCallOnMount === undefined || !dontCallOnMount) {
      call()
    }
  }, [call, dontCallOnMount])

  return { call, loading, data, error }
}
