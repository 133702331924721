import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { createMigrate, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

import clients from "./clients/reducer"
import settings from "./settings/reducer"

import IClientsState from "./clients/models/IClientsState"
import ISettingsState from "./settings/models/ISettingsState"
import axios from "axios"

export interface IBooksStore {
  clients: IClientsState
  settings: ISettingsState
}

const rootReducer = combineReducers({ clients, settings })

const migrations = {}

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  whitelist: ["settings"], // only settings will be persisted
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer: any = typeof window !== "undefined" ? persistReducer(persistConfig, rootReducer) : rootReducer

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

// noinspection JSUnresolvedVariable
const composeEnhancers =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

/**
 * Load the axios token on store load.
 */
const axiosTokenHydrate = (): void => {
  const { settings } = store.getState() as IBooksStore
  if (settings.currentUser?.token !== undefined) {
    axios.defaults.headers.common.Authorization = `Bearer ${settings.currentUser?.token.access}`
  }
}

const persistor = typeof window !== "undefined" ? persistStore(store, null, axiosTokenHydrate) : null

export default { store, persistor }
