import * as React from "react"
import { useForm } from "react-hook-form"
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import StandForm from "./components/StandForm"
import IStand, { STANDS_ENDPOINT } from "../../shared/models/core/IStand"
import { RestRepository } from "../../shared/repositories/RestRepository"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"
import useApiAdd, { IUseApiAddProps } from "../../shared/hooks/useApiAdd"

const repository = new RestRepository<IStand>(STANDS_ENDPOINT)

/**
 * Page for adding a stand.
 *
 * @class
 */
const Add: React.FunctionComponent = () => {
  const { control, handleSubmit, setError } = useForm<any>()

  const props: IUseApiAddProps<IStand> = { apiFunction: repository.add, setError }
  const { saving, handleAdd, connectionError } = useApiAdd<IStand>(props)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            Add Stand
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ViewLoading loading={saving} />
          <ErrorMessage error={connectionError} />
        </Grid>
      </Grid>
      <Paper sx={{ mt: 4, p: 4 }}>
        <Box component="form" onSubmit={handleSubmit(handleAdd)} autoComplete="off">
          <StandForm control={control} />
          <Divider sx={{ mt: 2 }} />
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs />
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

export default Add
