import * as React from "react"
import { useCallback } from "react"
import { Link } from "@reach/router"
import { Grid, Hidden, IconButton, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import Paper from "@mui/material/Paper"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import { metersToInches } from "../../shared/utilities/formatters"
import DeleteIcon from "@mui/icons-material/Delete"
import { RestRepository } from "../../shared/repositories/RestRepository"
import IProductTemplate, { PRODUCT_TEMPLATE_ENDPOINT } from "../../shared/models/core/IProductTemplate"
import { IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { navigate } from "gatsby"
import { PRODUCT_TEMPLATES_ADD_URL, PRODUCT_TEMPLATES_EDIT_URL } from "../../config/urls"
import TableLoading from "../../shared/components/TableLoading"
import TablePaging from "../../shared/components/tables/TablePaging"
import TableRowSelect from "../../shared/components/TableRowSelect"

const repository = new RestRepository<IProductTemplate>(PRODUCT_TEMPLATE_ENDPOINT)

/**
 * This page displays a list of all the product templates.
 *
 * @class
 */
const Index: React.FunctionComponent = () => {
  const props: IUseApiPagedResultsProps<IProductTemplate> = { apiFunction: repository.findAll }
  const {
    data,
    count,
    paging,
    loading,
    call: loadProductTemplates,
    handlePaging,
  } = useApiPaged<IProductTemplate>(props)

  const handleSelected = useCallback(async (pt: IProductTemplate) => {
    await navigate(`${PRODUCT_TEMPLATES_EDIT_URL}/${pt.id}`)
  }, [])

  const handleDelete = useCallback(
    (id: number) => async () => {
      try {
        await repository.delete(id)
        await loadProductTemplates()
      } catch (errors) {}
    },
    [loadProductTemplates]
  )

  return (
    <>
      <Grid container alignItems="baseline">
        <Grid item xs>
          <Typography variant="h2" component="h2">
            Product Templates
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" component={Link} to={PRODUCT_TEMPLATES_ADD_URL}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ mt: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <Hidden mdDown>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Min Length</TableCell>
                      <TableCell align="right">Cut To Length</TableCell>
                      <TableCell align="right">Min Butt</TableCell>
                      <TableCell align="right">Max Butt</TableCell>
                      <TableCell align="right">Min DBH</TableCell>
                      <TableCell align="right">Max DBH</TableCell>
                      <TableCell align="right">Min Top</TableCell>
                      <TableCell align="right">Price/Ton</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                </Hidden>
                <TableBody>
                  <TableLoading loading={loading} columns={79} rows={paging?.limit} />
                  {!loading &&
                    data?.results.map(product => (
                      <TableRowSelect key={product.id} item={product} onSelected={handleSelected}>
                        <TableCell component="th" scope="row">
                          {product.name}
                        </TableCell>
                        <Hidden mdDown>
                          <TableCell align="right">{metersToInches(product.min_length)}</TableCell>
                          <TableCell align="right">{metersToInches(product.cut_to_length)}</TableCell>

                          <TableCell align="right">{metersToInches(product.min_butt)}</TableCell>
                          <TableCell align="right">{metersToInches(product.max_butt)}</TableCell>

                          <TableCell align="right">{metersToInches(product.min_dbh)}</TableCell>
                          <TableCell align="right">{metersToInches(product.max_dbh)}</TableCell>

                          <TableCell align="right">{metersToInches(product.min_top)}</TableCell>
                          <TableCell align="right">${product.price_per_ton.toFixed(2)}</TableCell>
                        </Hidden>
                        <TableCell align="right">
                          <IconButton aria-label="delete" onClick={handleDelete(product.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRowSelect>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <TablePaging count={count} total={data?.count} page={paging?.page} handlePaging={handlePaging} />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Index
