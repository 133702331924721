import { Alert, CircularProgress, Grid, Snackbar } from "@mui/material"
import * as React from "react"

interface IProps {
  loading: boolean
  message?: string
  size?: string
}

/**
 * Displays a loading indicator at the top of the page.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ViewLoading: React.FunctionComponent<IProps> = (props: IProps) => {
  const { loading, message = "Loading...", size = "1.5rem" } = props
  return (
    <>
      <Snackbar open={loading} sx={{ mt: 8 }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="info" icon={false} elevation={8}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CircularProgress size={size} />
            </Grid>
            <Grid item xs>
              {message}
            </Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </>
  )
}

export default ViewLoading
