import * as React from "react"
import { Grid, Typography } from "@mui/material"

/**
 * Component is displayed when the user has no clients.
 *
 * @class
 */
const NoClients: React.FunctionComponent = () => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h3">No Clients Available</Typography>
    </Grid>
    <Grid item xs={12}>
      <p>Your account needs to be associated with a client. Please contact support.</p>
    </Grid>
  </Grid>
)

export default NoClients
