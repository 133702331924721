import { BASE_URL } from "../utilities/request_utility"
import { useCallback, useMemo } from "react"
import ICurrentUser from "../models/ICurrentUser"
import IToken from "../models/IToken"
import ICredentials from "../models/ICredentials"
import { navigate } from "gatsby"
import { LOGIN_URL } from "../../config/urls"
import { useDispatch, useSelector } from "react-redux"
import { IBooksStore } from "../../store"
import { shallowEqual } from "fast-equals"
import { operations } from "../../store/settings"
import * as UsersRepository from "../../shared/repositories/users_repository"
import { IConnectionError } from "../models/IConnectionError"
import axios from "axios"
import IClient from "../models/core/IClient"

const isBrowser = typeof window !== "undefined"

interface IUseAuth {
  loginWithToken: ((accessToken: string) => Promise<ICurrentUser>) | null
  loginWithCredentials: ((accessToken: ICredentials) => Promise<ICurrentUser>) | null
  logout: (() => void) | null
  logoutWithConnectionError: ((reason: any) => void) | null
  isLoggedIn: boolean | null
  currentUser: ICurrentUser | null
}

/**
 * Hook for managing authentication.
 *
 * @returns {IUseAuth} the use auth hook.
 */
const useAuth = (): IUseAuth => {
  if (typeof window === "undefined") {
    return {
      loginWithToken: null,
      loginWithCredentials: null,
      logout: null,
      logoutWithConnectionError: null,
      isLoggedIn: null,
      currentUser: null,
    }
  }
  const { settings } = useSelector((state: IBooksStore) => state, shallowEqual)
  const dispatch = useDispatch()

  const loadUserWithToken = useCallback(async (token: IToken): Promise<ICurrentUser> => {
    const user = await UsersRepository.me(token)
    const clients: IClient[] = []
    dispatch(operations.setCurrentUser({ user, clients, token }))
    return { user, clients, token }
  }, [])

  const loginWithCredentials = useCallback(async (credentials: ICredentials): Promise<ICurrentUser> => {
    const { data } = await axios.post(`${BASE_URL}/token/`, credentials)
    return await loadUserWithToken(data)
  }, [])

  const loginWithToken = useCallback(
    async (accessToken: string): Promise<ICurrentUser> => {
      // Need reset authorization token for login.
      axios.defaults.headers.common.Authorization = ""
      const { data } = await axios.post(`${BASE_URL}/rest-auth/google/`, { access_token: accessToken })
      const token: IToken = { refresh: data.refresh_token, access: data.access_token }
      axios.defaults.headers.common.Authorization = `Bearer ${token.access}`
      return await loadUserWithToken(token)
    },
    [loadUserWithToken]
  )

  const logout = useCallback(async () => {
    if (!isBrowser) {
      return
    }
    dispatch(operations.clearCurrentUser())
    await navigate(LOGIN_URL)
  }, [])

  /**
   * Logout a user if there is an authentication error.
   */
  const logoutWithConnectionError = useCallback(
    async (connectionError: IConnectionError) => {
      if (connectionError.code === "user_not_found" || connectionError.code === "token_not_valid") {
        await logout()
      }
    },
    [logout]
  )

  /**
   * Check to see if the user is logged in.
   */
  const isLoggedIn = useMemo(() => {
    if (!isBrowser) {
      return false
    }
    return !(settings.currentUser == null)
  }, [settings.currentUser])

  return {
    loginWithToken,
    loginWithCredentials,
    logout,
    logoutWithConnectionError,
    isLoggedIn,
    currentUser: settings.currentUser,
  }
}

export default useAuth
