import { BASE_URL, urlSearchParams } from "../utilities/request_utility"
import { IPagedResults } from "../models/IPagedResults"
import { IPaging } from "../models/IPaging"
import axios from "axios"

/**
 * Generic class for making requests the api.
 */
export class RestRepository<T> {
  endpointName: string

  /**
   * Build a rest endpoint with an api path.
   *
   * @param {string} endpointName to send requests to.
   */
  constructor(endpointName: string) {
    this.endpointName = endpointName
  }

  findAll = async (paging?: IPaging): Promise<IPagedResults<T>> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axios.get(`${BASE_URL}/${this.endpointName}/${queryString}`)
    return data as IPagedResults<T>
  }

  list = async (paging?: IPaging): Promise<T[]> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axios.get(`${BASE_URL}/${this.endpointName}/list/${queryString}`)
    return data as T[]
  }

  read = async (id: string | number): Promise<T> => {
    const { data } = await axios.get(`${BASE_URL}/${this.endpointName}/${id}/`)
    return data as T
  }

  action = async (id: string | number, action: string, paging?: IPaging): Promise<T> => {
    const queryString = urlSearchParams(paging)
    const { data } = await axios.get(`${BASE_URL}/${this.endpointName}/${id}/${action}/${queryString}`)
    return data as T
  }

  add = async (item: T): Promise<T> => {
    const { data } = await axios.post(`${BASE_URL}/${this.endpointName}/`, item)
    return data as T
  }

  edit = async (item: T | FormData, id: string | number): Promise<T> => {
    const { data } = await axios.put(`${BASE_URL}/${this.endpointName}/${id}/`, item)
    return data as T
  }

  delete = async (id: string | number): Promise<void> => {
    await axios.delete(`${BASE_URL}/${this.endpointName}/${id}/`)
  }
}
