import { CONNECTION_ERROR, IConnectionError, setFormConnectionErrors, TSetFormError } from "../models/IConnectionError"
import { useCallback, useState } from "react"
import { useNavigate } from "@reach/router"
import { IMainModel } from "../models/IMainModel"

interface IUseApiEditResponse<T> {
  handleEdit: (t: T) => Promise<T | null>
  saving: boolean
  connectionError: IConnectionError | undefined
}

export interface IUseApiEditProps<T> {
  apiFunction: (item: T, id: string | number) => Promise<T>
  redirectView?: string
  setError: TSetFormError
  redirect?: boolean
}

/**
 * This hook will do all the heavy lifting of sending ends to the api.
 *
 * @param {IUseApiEditProps} props see IUseApiEditProps<T> for details.
 * @returns {IUseApiEditResponse} edit state
 */
const useApiEdit = <T extends IMainModel>(props: IUseApiEditProps<T>): IUseApiEditResponse<T> => {
  const { apiFunction, redirectView, setError, redirect = true } = props
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [connectionError, setConnectionError] = useState<IConnectionError | undefined>()

  const handleEdit = useCallback(
    async (t: T) => {
      setSaving(true)
      try {
        const c1 = await apiFunction(t, t.id)
        if (redirect) {
          if (redirectView != null) {
            await navigate(`${redirectView}/${c1.id}`)
          } else {
            if (typeof window !== "undefined" && window !== undefined) {
              window.history.go(-1)
            }
          }
        } else {
          setSaving(false)
        }
        return c1
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setFormConnectionErrors(reason.response.data, setError)
        } else {
          setConnectionError(CONNECTION_ERROR)
        }
        setSaving(false)
      }
      return null
    },
    [navigate]
  )

  return { saving, handleEdit, connectionError }
}

export default useApiEdit
