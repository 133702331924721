export const PRODUCT_TEMPLATE_ENDPOINT = "users/product_templates"

export default interface IProductTemplate {
  id: number
  name: string
  client: number
  cut_to_length: number
  min_butt: number
  max_butt: number
  min_dbh: number
  max_dbh: number
  min_length: number
  min_top: number
  price_per_ton: number
}
