import * as React from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import IState from "../../../shared/models/IState"
import ICountry from "../../../shared/models/ICountry"
import { findAllStates } from "../../../shared/repositories/StatesRepository"
import { findAllCountries } from "../../../shared/repositories/CountriesRepository"
import { Control } from "react-hook-form/dist/types/form"
import IStand from "../../../shared/models/core/IStand"
import ClientFormSelect from "../../../shared/components/ClientFormSelect"

const now = new Date().getUTCFullYear()
const years = Array(now - (now - 100))
  .fill("")
  .map((v, idx) => now - idx)

const states = findAllStates()
const countries = findAllCountries().filter((country: ICountry) => country.show)

export interface IProps {
  control: Control
  stand?: IStand | null
  isEdit?: boolean
}

/**
 * Form for editing or adding a stand.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const StandForm: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, stand, isEdit = false } = props
  if (isEdit && stand === null) {
    return <></>
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {isEdit && (
            <Controller
              name="id"
              control={control}
              render={({ field }) => <input type="hidden" {...field} />}
              defaultValue={stand !== null ? stand?.id : ""}
            />
          )}
          <Controller
            name="name"
            control={control}
            defaultValue={stand !== null ? stand?.name : ""}
            rules={{ required: { value: true, message: "Name is required." } }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="First Name"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <ClientFormSelect control={control} defaultValue={stand?.client} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={3}>
          <Controller
            name="age"
            control={control}
            defaultValue={stand !== null ? stand?.age : new Date().getFullYear()}
            rules={{ required: { value: true, message: "Age is required." } }}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel required id="age-select-label">
                  Age
                </InputLabel>
                <Select
                  required
                  labelId="age-select-label"
                  id="age-select"
                  label="Age"
                  error={Boolean(fieldState.error)}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  inputRef={field.ref}
                >
                  {years.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="region"
            control={control}
            defaultValue={stand !== null ? stand?.region : "inland"}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel required id="region-select-label">
                  Region
                </InputLabel>
                <Select
                  required
                  labelId="region-select-label"
                  id="region-select"
                  label="Region"
                  error={Boolean(fieldState.error)}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  inputRef={field.ref}
                >
                  <MenuItem value="inland">Inland</MenuItem>
                  <MenuItem value="coastal">Coastal</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <Controller
            name="county"
            control={control}
            defaultValue={stand !== null ? stand?.county : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="County"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={3}>
          <Controller
            name="state"
            control={control}
            defaultValue={stand !== null ? stand?.state : states[11].value}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel required id="state-select-label">
                  Region
                </InputLabel>
                <Select
                  required
                  labelId="state-select-label"
                  id="state-select"
                  label="State"
                  error={Boolean(fieldState.error)}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  inputRef={field.ref}
                >
                  {states.map((state: IState, index: number) => (
                    <MenuItem key={index} value={state.value}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="country"
            control={control}
            defaultValue={stand !== null ? stand?.country : countries[0].value}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel required id="country-select-label">
                  Country
                </InputLabel>
                <Select
                  required
                  labelId="country-select-label"
                  id="country-select"
                  label="Country"
                  error={Boolean(fieldState.error)}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  inputRef={field.ref}
                >
                  {countries.map((country: ICountry, index: number) => (
                    <MenuItem key={index} value={country.value}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StandForm
