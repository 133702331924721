import IProduct from "./IProduct"
import ICruise from "./ICruise"
import { IMainModel } from "../IMainModel"

export const STANDS_ENDPOINT = "users/stands"

export default interface IStand extends IMainModel {
  id: number
  name: string
  region: string
  area: number
  client: number
  age: number
  shape: [[number, number]]
  centroid: [number, number]
  state: string
  county: string
  country: string
  products: IProduct[]
  cruises: ICruise[]
}
