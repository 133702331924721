import IProductReport from "./IProductReport"
import IStand from "./IStand"
import { IPlot } from "./IPlot"

export const CRUISES_ENDPOINT = "users/cruises"

export default interface ICruise {
  plots: IPlot[]
  id: number
  stand: IStand
  cruise_date: string
  product_reports: IProductReport[]
  updated: string
  carrier: string
  coverage: number
  tree_count: number
  plot_width: number
  plot_shape_width: number
}
