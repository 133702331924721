import * as React from "react"
import { useForm } from "react-hook-form"
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import StandForm from "./components/StandForm"
import { useApiRead } from "../../shared/hooks/useApiRead"
import IStand, { STANDS_ENDPOINT } from "../../shared/models/core/IStand"
import { RestRepository } from "../../shared/repositories/RestRepository"
import ViewLoading from "../../shared/components/ViewLoading"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import ErrorMessage from "../../shared/components/ErrorMessage"

const repository = new RestRepository<IStand>(STANDS_ENDPOINT)

/**
 * Page for editing a stand.
 *
 * @class
 */
const Edit: React.FunctionComponent = () => {
  const { control, handleSubmit, setError } = useForm<any>()
  const { data, error, loading } = useApiRead<IStand>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IStand> = { apiFunction: repository.edit, setError }
  const { saving, handleEdit, connectionError } = useApiEdit<IStand>(props)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            Edit Stand
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ViewLoading loading={saving || loading} />
          <ErrorMessage error={error} />
          <ErrorMessage error={connectionError} />
        </Grid>
      </Grid>
      <Paper sx={{ mt: 4, p: 4 }}>
        <Box component="form" onSubmit={handleSubmit(handleEdit)} autoComplete="off">
          <StandForm control={control} stand={data} isEdit />
          <Divider sx={{ mt: 2 }} />
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs />
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

export default Edit
