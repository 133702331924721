import * as React from "react"
import { Control } from "react-hook-form/dist/types/form"
import { Grid, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import InputAdornment from "@mui/material/InputAdornment"
import IProductTemplate from "../../../shared/models/core/IProductTemplate"
import ClientFormSelect from "../../../shared/components/ClientFormSelect"

interface IProps {
  control: Control
  productTemplate?: IProductTemplate | null
  isEdit?: boolean
}

/**
 * Reusable for editing product templates.
 *
 * @param {IProps} props see IProps for details
 * @class
 */
const ProductTemplateForm: React.FunctionComponent<IProps> = (props: IProps) => {
  const { control, productTemplate = null, isEdit = false } = props
  if (isEdit && productTemplate == null) {
    return <></>
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isEdit && (
            <Controller
              name="id"
              control={control}
              render={({ field }) => <input type="hidden" {...field} />}
              defaultValue={productTemplate !== null ? productTemplate.id : ""}
            />
          )}
          <Controller
            name="name"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate.name : ""}
            rules={{ required: { value: true, message: "Name is required." } }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Name"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ClientFormSelect control={control} defaultValue={productTemplate?.client} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Controller
            name="min_length"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate.min_length : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Min Length"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="min_top"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate.min_top : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Min Top"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="cut_to_length"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate?.cut_to_length : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Cut To Length"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="min_butt"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate?.min_butt : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Min Butt"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="max_butt"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate?.max_butt : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Max Butt"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="min_dbh"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate?.min_dbh : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Min DBH"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="max_dbh"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate?.max_dbh : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Max DBH"
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="price_per_ton"
            control={control}
            defaultValue={productTemplate !== null ? productTemplate?.price_per_ton : ""}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Price/Ton"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                inputRef={field.ref}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ProductTemplateForm
