import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Link as RouterLink } from "@reach/router"
import { Grid, IconButton, Link, Paper, Table, Typography } from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import Button from "@mui/material/Button"
import { getAge, squareMetersToAcres } from "../../shared/utilities/formatters"
import LinearProgress from "@mui/material/LinearProgress"
import ICruise from "../../shared/models/core/ICruise"
import EditIcon from "@mui/icons-material/Edit"
import { useApiRead } from "../../shared/hooks/useApiRead"
import IStand, { STANDS_ENDPOINT } from "../../shared/models/core/IStand"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { CRUISES_VIEW_URL, STANDS_EDIT_URL, STANDS_VIEW_URL } from "../../config/urls"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { navigate } from "gatsby"
import Map from "../../shared/components/Map"
import FormatDate from "../../shared/components/format/FormatDate"
import ViewLoading from "../../shared/components/ViewLoading"

const repository = new RestRepository<IStand>(STANDS_ENDPOINT)

/**
 * View a stand.
 *
 * @class
 */
const View: React.FunctionComponent = () => {
  const { data, loading } = useApiRead<IStand>({ apiFunction: repository.read })
  const [stand, setStand] = useState<IStand | null>(null)
  const [isUploading, setUploading] = useState(false)

  const handleSelected = useCallback(async (stand: IStand) => {
    await navigate(`${CRUISES_VIEW_URL}/${stand.id}`)
  }, [])

  useEffect(() => {
    if (stand?.shape !== null) {
      setUploading(false)
    }
  }, [stand])

  const uploadFile = useCallback(
    async (e: any) => {
      if (e.target.files.length > 0 && stand != null) {
        setUploading(true)
        const data = new FormData()
        data.append("name", stand.name)
        data.append("shape_file", e.target.files[0])
        const s = await repository.edit(data, stand.id)
        setStand(s)
        setUploading(false)
      }
    },
    [stand]
  )

  useEffect(() => {
    if (stand === null && data !== undefined) {
      setStand(data)
    }
  }, [data])

  return (
    <>
      {stand !== null && (
        <Grid container spacing={2} alignItems="baseline" sx={{ mb: 2 }}>
          <Grid item>
            <Link component={RouterLink} to={`${STANDS_VIEW_URL}/${stand.id}`}>
              <Typography variant="h2" component="h2">
                {stand.name}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs>
            <ViewLoading loading={loading} />
          </Grid>
          <Grid item>
            <Button component={RouterLink} variant="contained" color="secondary" to={`${STANDS_EDIT_URL}/${stand.id}`}>
              Edit
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          {stand != null && (
            <Paper>
              {isUploading && <LinearProgress color="secondary" />}
              <Map shape={stand.shape} centroid={stand.centroid} />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {stand !== null && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Location
                    </TableCell>
                    <TableCell align="right">
                      {stand.county}, {stand.state}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Area
                    </TableCell>
                    <TableCell align="right">{squareMetersToAcres(stand.area)} acres</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Age
                    </TableCell>
                    <TableCell align="right">{getAge(stand.age)} years</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Cruises
                    </TableCell>
                    <TableCell align="right">{stand.cruises?.length}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>Products</Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            component={RouterLink}
                            to={`${STANDS_VIEW_URL}/${stand.id}/products`}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="right">{stand.products?.length}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isUploading && (
            <Grid container sx={{ mt: 2, mb: 2 }} alignItems="center">
              <Grid item xs>
                <label htmlFor="shapefile">
                  <input onChange={uploadFile} type="file" name="shapefile" id="shapefile" />
                </label>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" component="span">
                  Upload Shape File
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ ml: 2 }}>
                  Cruises
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Cruise ID</TableCell>
                        <TableCell align="left">Cruise Date</TableCell>
                        <TableCell align="center">Carrier</TableCell>
                        <TableCell align="right">Coverage</TableCell>
                        <TableCell align="right">Trees Found</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stand?.cruises === null || stand?.cruises?.length === 0 ? (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={8}>
                            No cruises yet.
                          </TableCell>
                        </TableRow>
                      ) : (
                        stand?.cruises?.map((cruise: ICruise) => (
                          <TableRowSelect key={cruise.id} item={cruise} onSelected={handleSelected}>
                            <TableCell align="left">{cruise.id}</TableCell>
                            <TableCell component="th" scope="row">
                              <FormatDate value={cruise.updated} />
                            </TableCell>
                            <TableCell align="center">{cruise.carrier}</TableCell>
                            <TableCell align="right">{cruise.coverage.toFixed(0)} %</TableCell>
                            <TableCell align="right">{cruise.tree_count}</TableCell>
                          </TableRowSelect>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default View
