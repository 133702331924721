import * as React from "react"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import ICruise from "../../../shared/models/core/ICruise"

interface IProps {
  cruise: ICruise
}

/**
 * Displays a table of products found during the cruise.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ProductsTable: React.FunctionComponent<IProps> = (props: IProps) => {
  const { cruise } = props
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="center">Volume</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Price per Ton</TableCell>
              <TableCell align="right">TPA</TableCell>
              <TableCell align="right">BA/ac</TableCell>
              <TableCell align="right">Avg. Height</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cruise.product_reports.map(product => (
              <TableRow key={product.id}>
                <TableCell component="th" scope="row">
                  {product.name}
                </TableCell>
                <TableCell align="center">{product.calc_volume.toFixed(2)}</TableCell>
                <TableCell align="right">${product.calc_volume_price.toFixed(2)}</TableCell>
                <TableCell align="right">${product.calc_price_per_ton.toFixed(2)}</TableCell>
                <TableCell align="right">{product.calc_tons_per_acre.toFixed(2)}</TableCell>
                <TableCell align="right">{product.calc_ba_ac.toFixed(2)}</TableCell>
                <TableCell align="right">{product.calc_avg_height.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ProductsTable
