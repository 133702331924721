import * as React from "react"
import { useForm } from "react-hook-form"
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import ProductTemplateForm from "./components/ProductTemplateForm"
import { RestRepository } from "../../shared/repositories/RestRepository"
import IProductTemplate, { PRODUCT_TEMPLATE_ENDPOINT } from "../../shared/models/core/IProductTemplate"
import { useApiRead } from "../../shared/hooks/useApiRead"
import useApiEdit, { IUseApiEditProps } from "../../shared/hooks/useApiEdit"
import ErrorMessage from "../../shared/components/ErrorMessage"
import ViewLoading from "../../shared/components/ViewLoading"

const repository = new RestRepository<IProductTemplate>(PRODUCT_TEMPLATE_ENDPOINT)

/**
 * Page to editing a product template.
 *
 * @class
 */
const Edit: React.FunctionComponent = () => {
  const { control, handleSubmit, setError } = useForm<any>()
  const { data, error, loading } = useApiRead<IProductTemplate>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IProductTemplate> = { apiFunction: repository.edit, setError }
  const { saving, handleEdit, connectionError } = useApiEdit<IProductTemplate>(props)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            Edit Product Template
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ViewLoading loading={saving || loading} />
          <ErrorMessage error={error} />
          <ErrorMessage error={connectionError} />
        </Grid>
      </Grid>
      <Paper sx={{ mt: 4, p: 4 }}>
        <Box component="form" onSubmit={handleSubmit(handleEdit)} autoComplete="off">
          <ProductTemplateForm control={control} productTemplate={data} isEdit />
          <Divider sx={{ mt: 2 }} />
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs />
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

export default Edit
