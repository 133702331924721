import * as React from "react"
import { useCallback } from "react"
import { Button, Grid, Paper, Typography } from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import Link from "@mui/material/Link"
import { Link as RouterLink } from "@reach/router"
import IProduct, { PRODUCT_ENDPOINT } from "../../shared/models/core/IProduct"
import ProductRow from "./components/ProductRow"
import { useApiRead } from "../../shared/hooks/useApiRead"
import IStand, { STANDS_ENDPOINT } from "../../shared/models/core/IStand"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { STANDS_VIEW_URL } from "../../config/urls"
import { useSelector } from "react-redux"
import { IBooksStore } from "../../store"

const standRepository = new RestRepository<IStand>(STANDS_ENDPOINT)
const productRepository = new RestRepository<IProduct | IProductForm>(PRODUCT_ENDPOINT)

interface IProductForm {
  stand: number
  client: number
}

/**
 * This component will add a product to a stand.
 *
 * @class
 */
const Products: React.FunctionComponent = () => {
  const client = useSelector((state: IBooksStore) => state.clients.item)
  const { data: stand, call: getStand } = useApiRead<IStand>({ apiFunction: standRepository.read })

  const handleAddProduct = useCallback(async () => {
    if (stand !== null && client !== null && client !== undefined) {
      const product: IProductForm = { stand: stand.id, client: client.id }
      await productRepository.add(product)
      await getStand()
    }
  }, [client, stand])

  const handleChange = useCallback(async () => {
    await getStand()
  }, [])

  return stand != null ? (
    <>
      <Grid container spacing={2} alignItems="baseline" sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h2" component="h2">
            Products
          </Typography>
        </Grid>
        <Grid item xs>
          <Link component={RouterLink} to={`${STANDS_VIEW_URL}/${stand.id}`}>
            <Typography variant="h4" component="h3">
              {stand.name}
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleAddProduct}>
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained" component={RouterLink} to={`${STANDS_VIEW_URL}/${stand.id}`}>
            Done
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Min Length</TableCell>
                  <TableCell align="right">Cut To Length</TableCell>
                  <TableCell align="right">Min Butt</TableCell>
                  <TableCell align="right">Max Butt</TableCell>
                  <TableCell align="right">Min DBH</TableCell>
                  <TableCell align="right">Max DBH</TableCell>
                  <TableCell align="right">Min Top</TableCell>
                  <TableCell align="right">Price/Ton</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stand.products.length > 0 ? (
                  stand.products.map((product: IProduct) => (
                    <ProductRow key={product.id} product={product} onChange={handleChange} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={9}>
                      No products yet.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  )
}

export default Products
