import * as React from "react"
import { useCallback } from "react"
import { Button, Grid, Typography } from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import { getAge, squareMetersToAcres } from "../../shared/utilities/formatters"
import { Link } from "@reach/router"
import { RestRepository } from "../../shared/repositories/RestRepository"
import IStand, { STANDS_ENDPOINT } from "../../shared/models/core/IStand"
import { IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import TablePaging from "../../shared/components/tables/TablePaging"
import TableLoading from "../../shared/components/TableLoading"
import TableRowSelect from "../../shared/components/TableRowSelect"
import { STANDS_ADD_URL, STANDS_VIEW_URL } from "../../config/urls"
import { navigate } from "gatsby"
import TableOrdering from "../../shared/components/TableOrdering"
import FormatDate from "../../shared/components/format/FormatDate"
import ViewLoading from "../../shared/components/ViewLoading"

const repository = new RestRepository<IStand>(STANDS_ENDPOINT)

/**
 * This page displays all the stands for the client.
 *
 * @class
 */
const Index: React.FunctionComponent = () => {
  const props: IUseApiPagedResultsProps<IStand> = { apiFunction: repository.findAll }
  const { data, count, paging, loading, handlePaging, handleOrdering } = useApiPaged<IStand>(props)

  const handleSelected = useCallback(async (stand: IStand) => {
    await navigate(`${STANDS_VIEW_URL}/${stand.id}`)
  }, [])

  return (
    <>
      <Grid container alignItems="baseline">
        <Grid item xs>
          <Typography variant="h2" component="h2">
            Stands
          </Typography>
          <ViewLoading loading={loading} />
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" component={Link} to={STANDS_ADD_URL}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ mt: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableOrdering
                        ordering={paging?.ordering}
                        field="name"
                        title="Name"
                        onOrdering={handleOrdering}
                      />
                    </TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell align="right">
                      Area <small>(ac)</small>
                    </TableCell>
                    <TableCell align="right">
                      Age <small>(yrs)</small>
                    </TableCell>
                    <TableCell align="right">Products</TableCell>
                    <TableCell align="right">Cruises</TableCell>
                    <TableCell>Last Cruised</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading loading={loading} columns={7} rows={paging?.limit} />
                  {!loading &&
                    data?.results.map(stand => (
                      <TableRowSelect key={stand.id} item={stand} onSelected={handleSelected}>
                        <TableCell>
                          <strong>{stand.name}</strong>
                        </TableCell>
                        <TableCell>
                          {stand.county}, {stand.state}
                        </TableCell>
                        <TableCell align="right">{squareMetersToAcres(stand.area)}</TableCell>
                        <TableCell align="right">{getAge(stand.age)}</TableCell>
                        <TableCell align="right">{stand.products.length}</TableCell>
                        <TableCell align="right">{stand.cruises.length}</TableCell>
                        <TableCell>
                          {stand.cruises.length > 0 ? <FormatDate value={stand.cruises[0].cruise_date} /> : "None"}
                        </TableCell>
                      </TableRowSelect>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <TablePaging count={count} total={data?.count} page={paging?.page} handlePaging={handlePaging} />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Index
